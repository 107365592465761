@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./_fonts.scss";
@import "swiper/swiper.scss";
@import "swiper/components/pagination/pagination.scss";
@import "swiper/components/navigation/navigation.scss";
@import "react-toastify/dist/ReactToastify.css";

html {
  scroll-behavior: smooth;
}

body {
  font-family: IRANSans, "exo 2", Arial, Helvetica, sans-serif;
}

.iransans-fanum {
  font-family: IRANSansFaNum, "exo 2", Arial, Helvetica, sans-serif;
}

.Toastify__toast-body {
  font-family: IRANSans, Arial, Helvetica, sans-serif !important;
}

.hero-touch {
  display: block;
  background: white;
  border-radius: 2rem;
  left: 10%;
  bottom: -5%;
  transform: translate(0%, 0%);
  box-shadow: -15px 60px 70px rgba($color: #adb9d6, $alpha: 0.3);
  padding: 30px 20px;
  width: 190px;
  -webkit-transform: rotate(-17deg);
  -moz-transform: rotate(-17deg);
  -o-transform: rotate(-17deg);
  border: solid 2px #ff4b55;
  @media (max-width: 768px) {
    width: 120px;
  }
  @media (max-width: 640px) {
    width: 90px;
    padding: 15px 10px;
  }
}

.hero-stay {
  background: white;
  border-radius: 2rem;
  bottom: 25%;
  right: 10%;
  transform: translate(25%, 10%);
  box-shadow: -15px 60px 70px rgba($color: #adb9d6, $alpha: 0.3);
  padding: 30px 20px;
  width: 180px;
  -webkit-transform: rotate(-11deg);
  -moz-transform: rotate(-11deg);
  -o-transform: rotate(-11deg);
  border: solid 2px #ff4b55;
  @media (max-width: 768px) {
    width: 120px;
  }
  @media (max-width: 640px) {
    width: 90px;
    padding: 15px 10px;
  }
}

.hero-stay-ltr {
  background: white;
  border-radius: 2rem;
  bottom: 25%;
  right: 10%;
  transform: translate(30%, 5%);
  box-shadow: -15px 60px 70px rgba($color: #adb9d6, $alpha: 0.3);
  padding: 30px 20px;
  width: 180px;
  -webkit-transform: rotate(-11deg);
  -moz-transform: rotate(-11deg);
  -o-transform: rotate(-11deg);
  border: solid 2px #ff4b55;

  @media (max-width: 768px) {
    width: 120px;
  }
  @media (max-width: 640px) {
    width: 90px;
    padding: 15px 10px;
  }
}

.hero-visit {
  background: white;
  border-radius: 2rem;
  top: 5%;
  left: 4%;
  transform: translate(-4%, 5%);
  box-shadow: -15px 60px 70px rgba($color: #adb9d6, $alpha: 0.3);
  padding: 30px 20px;
  width: 180px;
  -webkit-transform: rotate(34deg);
  -moz-transform: rotate(34deg);
  -o-transform: rotate(34deg);
  border: solid 2px #ff4b55;

  @media (max-width: 768px) {
    width: 120px;
  }
  @media (max-width: 640px) {
    width: 90px;
    padding: 15px 10px;
  }
}

.intro-bg {
  @media (max-width: 800px) {
    background-image: url("/images/intro/intro-bg-mobile.png");
  }
  background-image: url("/images/intro/intro-bg.png");
}

.intro-mobile {
  top: 60%;
  right: 50%;
  transform: translate(50%, -50%);
  z-index: 5;
}

.music-bg {
  @media (max-width: 800px) {
    background-image: url("/images/music/music-bg-mobile.png");
  }
  background-image: url("/images/music/music-bg.png");
}

.music-mobile {
  @media (max-width: 768px) {
    width: 600px;
  }
  @media (max-width: 640px) {
    width: 300px;
  }
  @media (max-width: 320px) {
    width: 280px;
  }

  top: 50%;
  left: 50%;
  transform: translate(-55%, -50%);
  z-index: 5;
  width: 530px;
}

.why-bg {
  @media (max-width: 800px) {
    background-image: url("/images/why/why-bg-mobile.png");
  }
  background-image: url("/images/why/why-bg.png");
}

.comment-dots {
  @media (max-width: 1024px) {
    display: none;
  }

  top: 19%;
  right: 50%;
  transform: translate(50%, 0);
}

.slider-wrapper {
  @media (max-width: 800px) {
    min-height: 700px;
    margin-left: 0px;
  }
  @media (max-width: 640px) {
    min-height: 400px;
    margin-left: 0px;
  }
  min-height: 700px;
  margin-left: 50px;
  position: relative;
}

.phone-mock {
  position: absolute;
  width: 40%;
  top: 50%;
  left: 20%;
  transform: translate(0, -50%);
  z-index: 99;
}

.slider-dots {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.blog-content {
  bottom: 15%;
  right: 50%;
  transform: translate(50%, 10%);
}

@keyframes modalFade {
  from {
    transform: translateY(-50%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all 500ms ease-in-out;
  animation-name: modalFade;
  animation-duration: 0.3s;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  z-index: 99 !important;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(43, 43, 43, 0.79);
}

#clients .swiper-slide {
  height: auto;
}

.react-time-picker__wrapper {
  border: unset !important;
}

.wm-list-icon {
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translate(-50%, 0);
}
